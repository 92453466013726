import React, { useEffect, useRef, useState } from "react";
import { Link } from "@inertiajs/react";
import useSupercluster from "use-supercluster";
import { Icon } from "@iconify/react";
import FrontText from "./FrontText";
import FrontRoundedButton from "./Buttons/FrontRoundedButton";
import { useTranslation } from "react-i18next";
import isWebpSupported from "@/Helpers/isWebpSupported";
import GoogleMap from "google-maps-react-markers";
import WebpImage from "./Blocks/WebpImage";

const FrontMarkerMap = ({ mapCenter, initialZoom, allWarehouses, initialLat = 0.0, initialLng = 0.0 }) => {
    const { t, i18n } = useTranslation();

    const mapRef = useRef();
    const isServer = typeof window === 'undefined'

    const [googleMapInitialized, setGoogleMapInitialized] = useState(false);

    // const staticMapImageUrl = isWebpSupported()
    //     ? '/storage/images/static_map.webp'
    //     : '/storage/images/static_map.png';

    const staticMapImageStyle = {
        backgroundImage: `url(/storage/images/static_map.webp), url(/storage/images/static_map.png)`
    }

    /* =================== [Bounds] =================== */  
    const [bounds, setBounds] = useState(null);
    const [zoom, setZoom] = useState(10);
    const [isActiveMarker, setIsActiveMarker] = useState({'warehouseName': false});

    /* =================== [Map] =================== */
    const googleMapsApiKey = import.meta.env.VITE_GOOGLE_MAP_API_KEY;
    const zoomLevel = initialZoom || 6.7;
    
    const location = mapCenter || {lat: (initialLat != 0.0 && !isNaN(initialLat)) ? initialLat : 51.9696521, lng: (initialLng != 0.0 && !isNaN(initialLng)) ? initialLng : 19.1966787};
    
    useEffect(() => {
        const handler = () => {
            setGoogleMapInitialized(true);
        };

        if(!isServer){
            if (document.readyState === "complete") {
                handler();
            } else {
                window.addEventListener('load', handler);
                return () => document.removeEventListener('load', handler);
            }
        }
    }, []);

    /* =================== [Map data] =================== */
    const points = allWarehouses.map(warehouse => ({
        type: 'Feature',
        properties: {
            cluster: false,
            warehouseName: warehouse.name,
            image: warehouse.gallery_main,
            totalArea: warehouse.total_area_building,
            slug: warehouse.slug,
            regionSlug: warehouse.get_region?.slug,
            regionSlugEn: warehouse.get_region?.slug_en,
            markerType: warehouse.markerType
        },
        geometry: {
            type: 'point',
            coordinates: [
                parseFloat(warehouse.lng),
                parseFloat(warehouse.lat),
            ]
        }
    }));

    /* =================== [Clusters] =================== */
    const { clusters, supercluster } = useSupercluster({
        points,
        bounds,
        zoom,
        options: { radius: 75, maxZoom: 20 }
    });

    const Marker = ({ children, className }) => (
        <div className={className}>
            {children}
        </div>
    );

    const HandleMarkerClick = (name, state) => {
        setIsActiveMarker({[name]: state});
    }


    const onMapChange = ({ bounds, zoom }) => {
        const ne = bounds.getNorthEast()
        const sw = bounds.getSouthWest()
        setBounds([sw.lng(), sw.lat(), ne.lng(), ne.lat()]);
        setZoom(zoom);
      }
      
    const onMarkerClick = (cluster) => {
        const [longitude, latitude] = cluster.geometry.coordinates;

        HandleMarkerClick(
            cluster.properties.warehouseName,
            isActiveMarker[cluster.properties.warehouseName]
                ? !isActiveMarker[cluster.properties.warehouseName]
                : true
        );

        mapRef.current.panTo({lat: latitude, lng: longitude})

        const zoomToMarkerLevel = 9;
        const currentZoom = mapRef.current.getZoom();

        if (currentZoom !== zoomToMarkerLevel && currentZoom < zoomToMarkerLevel) {
            mapRef.current.setZoom(zoomToMarkerLevel);
        }
    };

    const hasClusterDefaultLeaves = (cluster) => {
        return supercluster
            .getLeaves(cluster.id, Infinity, 0)
            .some(marker => marker.properties.markerType === 'default');
    };

    return (
        <div className="google-map h-[750px] font-sans bg-no-repeat bg-center bg-cover" style={staticMapImageStyle}>
            {
                !googleMapInitialized
                || (
                    <GoogleMap
                        apiKey={googleMapsApiKey}
                        defaultCenter={location}
                        defaultZoom={zoomLevel}
                        yesIWantToUseGoogleMapApiInternals
                        options={{
                            "streetViewControl": false,
                            "mapTypeControl": false,
                        }}
                        onGoogleApiLoaded={({ map }) => {
                            mapRef.current = map;
                        }}
                        onChange={onMapChange}
                    >

                        {clusters.map(cluster => {
                            const [longitude, latitude] = cluster.geometry.coordinates;

                            const {
                                cluster: isCluster,
                                point_count: pointCount
                            } = cluster.properties;

                            if (isCluster) {
                                const hasDefaultLeaves = hasClusterDefaultLeaves(cluster);

                                const markerClusterImage = hasDefaultLeaves
                                    ? '/storage/icons/map-marker.png'
                                    : '/storage/icons/map-marker-red.png';

                                return (
                                    <Marker
                                        key={`cluster-${cluster.id}`}
                                        lat={latitude}
                                        lng={longitude}
                                        zIndex={1}
                                    >
                                        <div
                                            className="cluster-marker"
                                            style={{
                                                width: `${50 + (pointCount / points.length) * 20}px`,
                                                height: `${50 + (pointCount / points.length) * 20}px`
                                            }}
                                            onClick={() => {
                                                const expansionZoom = Math.min(
                                                supercluster.getClusterExpansionZoom(cluster.id),
                                                20
                                                );
                                                mapRef.current.setZoom(expansionZoom);
                                                mapRef.current.panTo({ lat: latitude, lng: longitude });
                                            }}
                                        >
                                            <img src={markerClusterImage} alt="Map Marker" width={50} height={50}/>
                                            <span>{pointCount}</span>
                                        </div>
                                    </Marker>
                                );
                            }

                            const markerPinImage = cluster.properties.markerType === 'danger'
                                ? '/storage/icons/map-pin-red.png'
                                : '/storage/icons/map-pin.png';

                            return (
                                <Marker
                                    key={`warehouse-${cluster.properties.warehouseName}`}
                                    lat={latitude}
                                    lng={longitude}
                                    className="relative"
                                    zIndex={isActiveMarker[cluster.properties.warehouseName] ? 10 : 1}
                                >
                                    <div className="relative w-[50px]">
                                        <button className="warehouse-marker" onClick={onMarkerClick.bind(this, cluster)}>
                                            <img src={markerPinImage} alt="Map Pin" width={50} height={50}/>
                                        </button>

                                        {isActiveMarker[cluster.properties.warehouseName] && (
                                            <div className="z-50 absolute bottom-full left-1/2 transform -translate-x-1/2 shrink-0">
                                                <Link href={route('front.warehouses.single', {province: i18n.resolvedLanguage === 'pl' ? cluster.properties.regionSlug : cluster.properties.regionSlugEn, slug: cluster.properties.slug})} title={t('general.goToWarehouse') + ' - ' + cluster.properties.warehouseName} className="[&:hover_.box-wrapper]:bg-new-blue [&:hover_.hover-yellow]:text-yellow hover:text-white text-gray-text">
                                                    <div className="box-wrapper transition-all ease-in-out duration-200 flex gap-x-4 rounded-10 shadow-md bg-white p-3 w-80">
                                                        <div className="w-1/3 shrink-0">
                                                            <WebpImage
                                                                className="object-center object-cover h-full w-full rounded-4"
                                                                sources={[
                                                                    {
                                                                        width: 768,
                                                                        path: `/storage/warehouses/${cluster.properties.image.substring(0, cluster.properties.image.lastIndexOf('.'))}-300`,
                                                                        mime: cluster.properties.image.split('.').pop(),
                                                                    },
                                                                    {
                                                                        width: 1,
                                                                        path: `/storage/warehouses/${cluster.properties.image.substring(0, cluster.properties.image.lastIndexOf('.'))}-700`,
                                                                        mime: cluster.properties.image.split('.').pop(),
                                                                    },
                                                                ]}
                                                                alt={t('general.warehouse') + ' - ' + cluster.properties.warehouseName} 
                                                                fallback={`/storage/warehouses/${cluster.properties.image}`}
                                                                seo={{
                                                                    width: 100,
                                                                    height: 100,
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="w-2/3">
                                                            <FrontText textSize='14/17' className="transition-all ease-in-out duration-200 font-bold hover-yellow text-dark-blue-standard font-domine mb-2">
                                                                <span>
                                                                    {cluster.properties.warehouseName}
                                                                </span>
                                                            </FrontText>

                                                            {/* =================== [Powierzchnia całkowita- usunąć z miniaturek Dodatek #9 -> Zakomentować] =================== */}
                                                            <FrontText textSize='12/15' className="mb-4">
                                                                <span>{t('general.totalArea')} </span>
                                                                        <span className="transition-all ease-in-out duration-200 hover-yellow text-dark-blue-standard font-medium whitespace-nowrap">{cluster.properties.totalArea} m<sup>2</sup></span>
                                                            </FrontText>
                                                            {/* =================== [Powierzchnia całkowita- usunąć z miniaturek Dodatek #9 -> Zakomentować] =================== */}

                                                            <FrontRoundedButton>
                                                                {t('general.more')}
                                                            </FrontRoundedButton>
                                                        </div>
                                                    </div>
                                                </Link>
                                                <button title={t('general.close')} type="button" onClick={() => HandleMarkerClick(cluster.properties.warehouseName, false)} className="p-1 rounded-full bg-white text-dark-blue-standard hover:text-white hover:bg-dark-blue-standard transition-all ease-in-out duration-300 mx-auto flex shadow-lg -mt-1 border border-dark-blue-standard">
                                                    <Icon icon={'ph:x-bold'} width={16} height={16}/>
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </Marker>
                            );
                        })}
                    </GoogleMap>
                )
            }
        </div>
    );
};

export default FrontMarkerMap;